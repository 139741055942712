<template>
  <section class="main">
    <PageTitle title="Bageri &amp; Konditori i Trollhättan - Dagmar Carlssons Hembageri Eftr." />
    <Carousel />
    <section class="content-area light">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-md-8">
          <h2 class="underline">Beställ och få det levererat.</h2>
          <p>Om du inte har tid eller möjlighet att ta dig till oss så kan vi leverera din beställning. Hör av dig till oss för mer information.</p>
          <p class="info">Obs! Detta gäller endast inom centrala Trollhättan.</p>
          <a class="read-more" routerlink="/kontakta-oss" href="/kontakta-oss">Kontakta oss angående leverans</a>
        </div>
        <div class="col-sm-4 col-md-4 margin-top">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <OpeningHours />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2 class="underline">Kontaktuppgifter</h2>
              <p><span class="fa fa-map-marker"></span>Kungsgatan 16, 461 31, Trollhättan.</p>
              <p><span class="fa fa-phone"></span>0520-10671 / 0733166915</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  </section>
</template>

<script>
import { PageTitle, Carousel, OpeningHours } from '@/components'

export default {
  components: {
    PageTitle,
    Carousel,
    OpeningHours,
  },
  metaInfo: {
    title: 'Bageri & Konditori i Trollhättan - Dagmar Carlssons Hembageri Eftr.',
    description: 'Dagmar Carlssons Hemageri Eftr. i Trollhättan är det flexibla kvartersbageriet. Hos oss finner man goda tårtor och bakelser, men också gott och nyttigt bröd.',
  },
}
</script>

<style>

</style>